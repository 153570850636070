@use "../../base/mixins.scss" as *;

.our-commitments {
  .our-commitments-nuvole {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 17;
    will-change: transform;
    #nuvola1 {
      position: absolute;
      top: 31vh;
      left: -1vw;
      width: 14vh;
      height: auto;
      opacity: 0;
    }
    #nuvola2 {
      position: absolute;
      top: 48vh;
      left: 11vw;
      width: 12vh;
      height: auto;
      opacity: 0;
    }
    #nuvola3 {
      position: absolute;
      top: 63vh;
      left: 40vw;
      width: 7vh;
      height: auto;
      opacity: 0;
    }
    #nuvola4 {
      position: absolute;
      top: 55vh;
      left: 34vw;
      width: 9vh;
      height: auto;
      opacity: 0;
    }
  }
}
