@use "../../base/mixins.scss" as *;

.our-commitments {
  article {
    .our-commitments-titolo {
      position: fixed;
      top: 15vh;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      z-index: 15;
      flex-wrap: wrap;
      .our-commitments-text {
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        transform: translate3d(0, -0.2rem, 0);
        .fade-in-up {
          width: calc(var(--font-size-60pt) * 12.42);
          background-color: rgba(0, 0, 0, 0.8);
          transform: translate3d(0, 15vh, 0);
          p {
            font-size: calc(var(--font-size-60pt) * 0.2);
            padding-top: 0.8rem;
            color: white;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
      .fade-in-up {
        transform: translate3d(0, -25vh, 0);
        .text-with-background {
          padding: 0 2rem;
        }
        h1 {
          @include font(60);
          padding: 0;
          span {
            color: var(--verde);
          }
        }
      }
    }
  }
}
