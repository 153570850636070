@use "../../base/mixins.scss" as *;

.our-commitments {
  .our-commitments-mezzi {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 14;
    will-change: transform;
    .mezzo {
      opacity: 0;
    }
    #camion-rifiuti {
      position: absolute;
      top: 16.7vh;
      left: 68.5vh;
      width: 4.8vh;
      height: auto;
    }
    #auto {
      position: absolute;
      top: 34.5vh;
      left: 92.3vh;
      width: 2.5vh;
      height: auto;
    }
    #camion {
      position: absolute;
      top: 56.4vh;
      left: 21.5vh;
      width: 4.8vh;
      height: auto;
    }
    #camion-merci {
      position: absolute;
      top: -5.5vh;
      left: 99.5vh;
      width: 4.8vh;
      height: auto;
    }
  }
}
