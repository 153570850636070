.our-commitments {
  #our-commitments-progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.9rem;
    z-index: 10;
    transform: translateY(-1.5rem);
    overflow: hidden;
    #our-commitments-progress-bar-progress {
      width: 0;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}
