main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  article {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .rombo {
    width: 0.85rem;
    height: auto;
    rect {
      fill: var(--verde);
    }
  }
}
