@use "../../base/mixins.scss" as *;

@keyframes switches-background {
  0% {
    background: transparent;
  }
  100% {
    background: rgba(0, 0, 0, 0.8);
  }
}

@keyframes scroll-circle {
  0% {
    transform: translateY(0);
  }
  85% {
    transform: translateY(1.9rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes pallino-animation {
  0% {
    fill: var(--grigio-medio);
  }
  7% {
    fill: var(--verde);
  }
  12% {
    fill: var(--grigio-medio);
  }
  100% {
    fill: var(--grigio-medio);
  }
}

.our-commitments {
  .our-commitments-switches {
    position: fixed;
    top: auto;
    left: 0;
    bottom: 0;
    padding: 0.8rem 0 0 0.8rem;
    width: 86.1rem;
    height: 6.6rem;
    margin-left: calc((100vw - 86.1rem) / 2);
    background: transparent;
    animation: switches-background 0.8s 2.2s forwards;
    z-index: 15;
    overflow: hidden;
    transition: background-color 0.6s var(--easing);
    &:has(.mappa-switch-switch.active) {
      path {
        animation: none !important;
      }
    }
    & > div {
      &:first-of-type {
        .switch-pallino {
          path {
            animation: pallino-animation 7s infinite;
          }
        }
      }
      &:nth-of-type(2) {
        .switch-pallino {
          path {
            animation: pallino-animation 7s 0.4s infinite;
          }
        }
      }
      &:nth-of-type(3) {
        .switch-pallino {
          path {
            animation: pallino-animation 7s 0.8s infinite;
          }
        }
      }
      &:nth-of-type(4) {
        .switch-pallino {
          path {
            animation: pallino-animation 7s 1.2s infinite;
          }
        }
      }
      &:nth-of-type(5) {
        .switch-pallino {
          path {
            animation: pallino-animation 7s 1.5s infinite;
          }
        }
      }
    }
    .our-commitments-switch-cont-cont {
      position: relative;
      display: inline-block;
      overflow: hidden;
      width: 16.3rem;
      height: 5rem;
      z-index: 5;
      margin-right: 0.7rem;
    }
    .our-commitments-switch-cont {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      background-color: var(--grigio);
      padding: 1.2rem 0 0.8rem 0;
      overflow: hidden;
      clip-path: polygon(0 0, 1rem 1rem, 2rem 0, 100% 0, 100% 100%, 0 100%);
      width: 16.3rem;
      height: 5rem;
      transition: height 0.6s var(--easing);
      h4 {
        flex: 0 0 10rem;
        @include font(24);
        font-weight: 900;
        text-transform: uppercase;
        color: black;
        padding: 0 0 0 0.8rem;
        span.pedice {
          @include font(20);
        }
        span.sottotitolo {
          display: block;
          @include font(16);
          line-height: 1em;
          font-weight: 700;
        }
      }
      .mappa-switch-switch {
        position: relative;
        flex: 0 0 5.5rem;
        padding: 0 0.6rem 0 0;
        cursor: pointer;
        svg:first-of-type {
          overflow: visible;
          transition: all 0.4s var(--easing);
          stroke: var(--verde);
          stroke-width: 2px;
          stroke-dasharray: 300;
          stroke-dashoffset: 300;
        }
        &:hover {
          svg:first-of-type {
            stroke-dashoffset: 0;
          }
          .switch-pallino {
            filter: drop-shadow(0 0 0.1rem rgb(0, 104, 47, 0.9));
          }
        }
        .switch-pallino {
          position: absolute;
          width: 1.9rem;
          height: 1.9rem;
          top: 0.35rem;
          left: 0;
          margin-left: 0.4rem;
          transition: all 0.3s var(--easing);
          &:hover {
            filter: drop-shadow(0 0 0.1rem rgb(0, 104, 47, 0.7));
          }
          path {
            fill: var(--grigio-medio);
          }
        }
        &.active {
          .switch-pallino {
            margin-left: 2.6rem;
            path {
              fill: var(--verde);
            }
          }
        }
      }
    }
  }
  .scroll-tip-cont {
    position: fixed;
    top: auto;
    left: calc((100vw - 86rem) / 2);
    bottom: 7rem;
    z-index: 15;
    #scroll-tip {
      display: flex;
      #nome-sezione-freccia {
        display: inline-flex;
        .scroll-tip-testo {
          background: rgba(0, 0, 0, 0.8);
          width: 19rem;
          height: 3.5rem;
          overflow: hidden;
          .scroll-tip-testo-cont {
            width: 100%;
            height: 3.5rem;
            display: flex;
            padding: 0.65rem 0.8rem 0.5rem 0.8rem;
            p {
              width: 100%;
              color: white;
              text-transform: uppercase;
              text-align: center;
              font-weight: 700;
              padding: 0.5rem 0 0 0;
              @include mobile {
                @include font(22);
              }
            }
            &#scroll-tip-testo-primo {
              & > svg {
                width: 1.4rem;
                height: auto;
                circle {
                  animation: scroll-circle 2s ease infinite;
                }
              }
            }
            &#scroll-tip-testo-secondo {
              padding-top: 2rem;
              height: 5.5rem;
              cursor: pointer;
              p {
                @include font(22);
                color: black;
                padding-top: 0.7rem;
                background-color: var(--grigio);
                border-radius: 0 0.2rem 0.2rem 0;
                height: 2.5rem;
              }
              & > svg {
                width: 2.5rem;
                height: 2.5rem;
                background: var(--grigio);
                border-radius: 0.2rem 0 0 0.2rem;
                padding-left: 0.8rem;
                line,
                polyline {
                  stroke: black;
                  stroke-width: 0.4rem;
                }
              }
            }
          }
        }
        .link-freccia-before {
          width: auto;
          height: 3.5rem;
          path {
            fill: rgba(0, 0, 0, 0.8);
          }
        }
        .link-freccia-after {
          width: auto;
          height: 3.5rem;
          path {
            fill: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }
  }
}
