@mixin desktopOnly {
  @media screen and (min-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

@mixin desktopBigOnly {
  @media screen and (min-width: 1441px) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile {
  @media (hover: none) and (pointer: coarse) and (not (any-pointer: fine)),
    (min-device-pixel-ratio: 2),
    (max-width: 55.75em) {
    @content;
  }
}

@mixin schermiStretti {
  @media (min-aspect-ratio: 2.2) {
    @content;
  }
}

@mixin hover {
  &:hover {
    @content;
  }
}

@mixin font($font-pt) {
  @if $font-pt == 158 {
    font-size: var(--font-size-158pt);
    line-height: 1em;
  } @else if $font-pt == 96 {
    font-size: var(--font-size-96pt);
    line-height: 1em;
  } @else if $font-pt == 72 {
    font-size: var(--font-size-72pt);
    line-height: 1em;
  } @else if $font-pt == 60 {
    font-size: var(--font-size-60pt);
    line-height: 1.3em;
  } @else if $font-pt == 48 {
    font-size: var(--font-size-48pt);
    line-height: 1em;
  } @else if $font-pt == 37 {
    font-size: var(--font-size-37pt);
    line-height: 1.3em;
  } @else if $font-pt == 34 {
    font-size: var(--font-size-34pt);
    line-height: 1.5em;
  } @else if $font-pt == 28 {
    font-size: var(--font-size-28pt);
    line-height: 1em;
  } @else if $font-pt == 24 {
    font-size: var(--font-size-24pt);
    line-height: 1em;
  } @else if $font-pt == 22 {
    font-size: var(--font-size-22pt);
    line-height: 1.3em;
  } @else if $font-pt == 20 {
    font-size: var(--font-size-20pt);
    line-height: 1.3em;
  } @else if $font-pt == 16 {
    font-size: var(--font-size-16pt);
    line-height: 1.2em;
  } @else if $font-pt == 15 {
    font-size: var(--font-size-15pt);
    line-height: 1em;
  } @else if $font-pt == 14 {
    font-size: var(--font-size-14pt);
    line-height: 1.1em;
  } @else if $font-pt == 13 {
    font-size: var(--font-size-13pt);
    line-height: 1.3em;
  }
}
