@use "../../base/mixins.scss" as *;

.our-commitments {
  .our-commitment-pallino {
    .our-commitment-pallino-cont.pallino-fightingco2 {
      .our-commitments-tooltip {
        .our-commitments-tooltip-cont {
          background-color: var(--verde);
          border: 2px solid var(--verde);
        }
        & > svg {
          polygon {
            fill: var(--verde);
          }
        }
      }
    }
  }
  #fightingCo21 {
    top: 34vh;
    left: 26.5vh;
  }
  #fightingCo22 {
    top: 46vh;
    left: 28vh;
  }
  #fightingCo23 {
    top: 48vh;
    left: 51vh;
  }
  #fightingCo24 {
    top: 58vh;
    left: 69vh;
  }
  #fightingCo25 {
    top: 69.6vh;
    left: 90vh;
  }
}
