@use "../../base/mixins.scss" as *;

.our-commitments {
  .our-commitment-pallino {
    .our-commitment-pallino-cont.pallino-investments {
      .our-commitments-tooltip {
        .our-commitments-tooltip-cont {
          background-color: var(--giallo);
          border: 2px solid var(--giallo);
          h3 {
            span {
              display: block;
              font-weight: 400;
              padding-top: 0.2rem;
            }
          }
        }
        & > svg {
          polygon {
            fill: var(--giallo);
          }
        }
      }
    }
  }
  #investments1 {
    top: 44vh;
    left: 12vh;
  }
  #investments2 {
    top: 41vh;
    left: 23.5vh;
  }
  #investments3 {
    top: 45.5vh;
    left: 31vh;
  }
  #investments4 {
    top: 40vh;
    left: 36vh;
  }
  #investments5 {
    top: 51.2vh;
    left: 46.3vh;
  }
  #investments6 {
    top: 48.3vh;
    left: 51.4vh;
  }
  #investments7 {
    top: 45.7vh;
    left: 55.7vh;
  }
  #investments8 {
    top: 58vh;
    left: 69vh;
  }
  /*   #investments9 {
    top: 69.6vh;
    left: 90vh;
  } */
}
