@use "../../base/mixins.scss" as *;

.our-commitments {
  .our-commitment-pallino {
    .our-commitment-pallino-cont.pallino-inout {
      .our-commitments-tooltip {
        .our-commitments-tooltip-cont {
          background-color: var(--rosa);
          border: 2px solid var(--rosa);
        }
        & > svg {
          polygon {
            fill: var(--rosa);
          }
        }
      }
    }
  }
  #inOut1 {
    top: 44.5vh;
    left: 13.5vh;
    .tooltip-icona-testo {
      justify-content: center;
      .tooltip-icona-testo-icona {
        flex: 0 0 1.4rem;
      }
      h3 {
        flex: 0 0 3rem;
        transform: translateY(-0.2rem);
      }
    }
  }

  #inOut2 {
    top: 39.5vh;
    left: 21.5vh;
    .tooltip-icona-testo {
      justify-content: center;
      .tooltip-icona-testo-icona {
        flex: 0 0 1.4rem;
      }
      h3 {
        flex: 0 0 3rem;
        transform: translateY(-0.2rem);
      }
    }
  }

  #inOut3 {
    top: 43.2vh;
    left: 28vh;
    .tooltip-icona-testo {
      justify-content: center;
      margin-left: -0.4rem;
      .tooltip-icona-testo-icona {
        flex: 0 0 1.4rem;
      }
    }
  }
  #inOut4 {
    top: 46.8vh;
    left: 34.4vh;
    .tooltip-icona-testo {
      justify-content: center;
      margin-left: -0.4rem;
      .tooltip-icona-testo-icona {
        flex: 0 0 1.4rem;
      }
      & > div:nth-of-type(2) {
        flex: 0 0 3.7rem;
        h3 {
          transform: translate(-0.55rem, 0.1rem);
        }
        p {
          padding: 0.2rem 0 0 0;
          svg {
            width: 0.35rem !important;
            margin-right: 0.15rem;
          }
        }
      }
      .tooltip-icona-testo-icona.secondo {
        flex: 0 0 1rem;
        transform: translate(-0.1rem, 0.1rem);
      }
    }
  }

  #inOut5 {
    top: 50vh;
    left: 48.5vh;
  }
  #inOut6 {
    top: 47vh;
    left: 53.7vh;
    .tooltip-icona-testo {
      justify-content: center;
      margin-left: -0.4rem;
      .tooltip-icona-testo-icona {
        flex: 0 0 1.4rem;
      }
      & > div:nth-of-type(2) {
        flex: 0 0 3.7rem;
        h3 {
          transform: translate(-0.55rem, 0.1rem);
        }
        p {
          padding: 0.2rem 0 0 0;
          svg {
            width: 0.35rem !important;
            margin-right: 0.15rem;
          }
        }
      }
      .tooltip-icona-testo-icona.secondo {
        flex: 0 0 1rem;
        transform: translate(-0.1rem, 0.1rem);
      }
    }
  }
  #inOut7 {
    top: 58vh;
    left: 69vh;
  }
  #inOut8 {
    top: 26.6vh;
    left: 31vh;
    @include hover {
      .fade-in-up
        .our-commitment-pallino-cont.pallino-inout
        .our-commitments-tooltip
        > svg {
        transform: translateY(-1rem) rotate(180deg);
      }
    }
    .fade-in-up {
      .our-commitment-pallino-cont.pallino-inout {
        .our-commitments-tooltip {
          .our-commitments-tooltip-cont {
            transform: translateY(3.2rem);
            @include schermiStretti {
              transform: translateX(5.2rem);
            }
          }
          & > svg {
            top: 1.7rem;
            transform: translateY(-1rem) rotate(180deg);
            @include schermiStretti {
              transform: translate(0.7rem, -2rem) rotate(90deg) !important;
            }
          }
        }
      }
    }
    .our-commitments-tooltip {
      left: calc(-3.7rem + 1.5vh);
      & > svg {
        margin-left: 3.1rem;
      }
      .our-commitments-tooltip-cont {
        img {
          width: 0.6rem;
          margin: 0 auto 0.2rem auto;
        }
      }
    }
  }
}
