@use "../../base/mixins.scss" as *;

.our-commitments {
  #mappa-our-commitments {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #57965d;
    z-index: 1;
    img {
      transform: translateZ(0);
      backface-visibility: hidden;
      will-change: transform;
    }
    #mappa-our-commitments-cont {
      position: fixed;
      width: auto;
      height: 100vh;
      transform: translateX(calc((100vw - 171.9vh) / 2 + 15vh));
      will-change: transform;
      opacity: 0;
      transition: opacity 0.1s var(--easing);
      &.show {
        opacity: 1;
      }
      .fondo-mappa {
        width: auto;
        height: 100vh;
        & > img {
          width: auto;
          height: 100%;
        }
        #villaggio1 {
          position: absolute;
          width: auto;
          top: 20.7vh;
          left: 25.1vh;
          height: 8.2vh;
          img {
            height: 100%;
            width: auto;
            transition: all 0.4s var(--easing);
          }
        }
        #villaggio2 {
          position: absolute;
          width: auto;
          top: 25.1vh;
          left: 35.2vh;
          height: 5.8vh;
          img {
            height: 100%;
            width: auto;
            transition: all 0.4s 0.3s var(--easing);
          }
        }
        #villaggio3 {
          position: absolute;
          width: auto;
          top: 27.5vh;
          left: 39.4vh;
          height: 5.8vh;
          img {
            height: 100%;
            width: auto;
            transition: all 0.4s 0.6s var(--easing);
          }
        }
        #villaggio4 {
          position: absolute;
          width: auto;
          top: 30.1vh;
          left: 31.6vh;
          height: 5.8vh;
          img {
            height: 100%;
            width: auto;
            transition: all 0.4s 0.9s var(--easing);
          }
        }
      }
      #popup {
        position: absolute;
        top: 43vh;
        left: 109vh;
        width: auto;
        height: 12vh;
        & > div {
          width: auto;
          height: 100%;
          img {
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }
}
