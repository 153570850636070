@use "../../base/mixins.scss" as *;

.text-with-background {
  display: block;
  width: fit-content;
  position: relative;
  padding: 1rem 3rem 0.35rem 3rem;
  h1,
  h2,
  h3,
  h4,
  p,
  a {
    position: relative;
    z-index: 2;
    @include font(158);
    font-weight: 900;
    text-transform: uppercase;
    transform-style: preserve-3d;
    perspective: 1000px;
    display: block;
    color: white;
    padding: 0;
    & > div {
      transform-style: preserve-3d;
      perspective: 1000px;
    }
    div > div {
      opacity: 0;
      transform-origin: 0% 80%;
      transform: translate3d(0%, 75%, -200px) rotateX(-90deg);
    }
  }
  .twb-before {
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    opacity: 0;
    transform: translateX(15vw) translateY(-2vw) skewX(-40deg);
  }
  .twb-after {
    display: block;
    width: 100%;
    height: 50%;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    background: black;
    opacity: 0;
    margin-top: -1px;
    transform: translateX(-15vw) translateY(2vw) skewX(-40deg);
  }
}
.no-background {
  .twb-before,
  .twb-after {
    background: transparent;
  }
}
