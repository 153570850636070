@use "mixins" as *;

@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("../../assets/fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-Medium.woff2") format("woff2"),
    url("../../assets/fonts/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("../../assets/fonts/Montserrat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../assets/fonts/Montserrat-Black.woff2") format("woff2"),
    url("../../assets/fonts/Montserrat-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

:root {
  font-size: calc((1vh + 4vw) * 0.24);
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "kern";
  font-kerning: normal;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  line-height: 1em;
  font-weight: 500;
  background-color: black;
  color: black;
}
h1 {
  @include font(48);
  padding-bottom: 2rem;
  text-transform: uppercase;
  color: white;
}
h2 {
  @include font(48);
  font-weight: 900;
  padding-bottom: 2rem;
  text-transform: uppercase;
}
h3 {
  @include font(37);
  padding-bottom: 2rem;
}
h4 {
  @include font(22);
  padding-bottom: 1.5rem;
}
p {
  @include font(22);
  padding-bottom: 0.8rem;
  @include mobile {
    @include font(34);
  }
}
a {
  text-decoration: none;
  color: inherit;
}
li {
  @include font(24);
  padding-bottom: 1.2rem;
}
strong,
bold {
  font-weight: 700;
}
em {
  font-style: italic;
}
