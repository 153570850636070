@use "../../base/mixins.scss" as *;

.our-commitments {
  .our-commitment-pallino {
    position: absolute;
    width: 3vh;
    height: 3vh;
    z-index: 1;
    will-change: transform;
    z-index: 20;
    cursor: pointer;
    display: none;
    &.play {
      display: block;
      z-index: 26;
    }
    @include hover {
      z-index: 27;
      .fade-in-up {
        .our-commitment-pallino-cont {
          .our-commitments-tooltip {
            transform: scale(1);
            svg {
              transform: translateY(0);
              opacity: 1;
            }
          }
          & > svg {
            transform: scale(0.8);
          }
        }
      }
    }
    .fade-in-up {
      transform: translate3d(0, 7vh, 0);
      .our-commitment-pallino-cont {
        & > svg {
          filter: drop-shadow(0 0.2vh 0.2vh rgba(0, 0, 0, 0.7));
          transition: all 0.4s var(--easing);
        }
        .our-commitments-tooltip {
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 0;
          left: calc(-3.75rem + 1.5vh);
          transform: scale(0);
          transition: all 0.3s var(--easing);
          transform-origin: bottom center;
          height: 0;
          overflow: visible;
          filter: drop-shadow(0 0.2vh 0.2vh rgba(0, 0, 0, 0.7));
          nobr {
            padding-left: 0.1rem;
          }
          .our-commitments-tooltip-cont {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 7.5rem;
            transform: translateY(calc(-100% - 2rem));
            z-index: 2;
            @include schermiStretti {
              transform: translateY(calc(-100% - 1rem));
            }
            // Static
            // a {
            //  display: none;
            // }
            //
            & > svg {
              transform: translateY(-0.9rem);
              margin-bottom: -0.9rem;
              width: 1.6rem;
              height: 1.6rem;
              z-index: 1;
            }
            .our-commitments-tooltip-titolo {
              display: flex;
              align-items: center;
              height: 2rem;
              transform: translateY(-0.5rem);
              margin-bottom: -0.5rem;
              h2 {
                width: 100%;
                font-size: 0.45rem;
                line-height: 0.55rem !important;
                font-weight: 700;
                text-transform: uppercase;
                line-height: 1em;
                padding: 0;
                color: white;
                text-align: center;
                padding: 0 0.5rem;
                white-space: pre-wrap;
              }
            }
            & > div:nth-of-type(2) {
              background-color: white;
              padding: 0.8rem 0.5rem 0.5rem 0.5rem;
              margin-top: -0.2rem;
              @include schermiStretti {
                padding-top: 0.5rem;
              }
              h3 {
                font-size: 0.38rem;
                line-height: 0.38rem;
                font-weight: 700;
                text-transform: uppercase;
                padding: 0 0 0.8rem 0;
                text-align: center;
                @include schermiStretti {
                  padding-bottom: 0.5rem;
                }
              }
              p {
                font-size: 0.38rem;
                line-height: 0.45rem;
                font-weight: 400;
                padding: 0 0 0.4rem 0;
                text-align: center;
                @include schermiStretti {
                  padding-bottom: 0;
                }
              }
              a {
                p {
                  text-transform: uppercase;
                  font-weight: 700;
                  padding-top: 0.1rem;
                }
              }
              .tooltip-icona-testo {
                display: flex;
                align-items: center;
                margin-bottom: 0.4rem;
                @include schermiStretti {
                  margin-bottom: 0.1rem;
                }
                &:last-of-type {
                  padding-bottom: 0.4rem;
                }
                .tooltip-icona-testo-icona {
                  flex: 0 0 1.3rem;
                  height: 1.3rem;
                  @include schermiStretti {
                    flex: 0 0 0.9rem;
                    height: 0.9rem;
                  }
                  svg,
                  img {
                    width: 100%;
                    height: auto;
                    overflow: visible;
                  }
                }
                h3 {
                  padding: 0 0 0 0.5rem;
                }
              }
            }
          }
          & > svg {
            position: absolute;
            top: -2.1rem;
            left: 0;
            width: 1.25rem;
            height: 2.5rem;
            margin-left: 3.2rem;
            padding-bottom: 0.7rem;
            transform: translateY(-1rem);
            opacity: 0;
            transition: all 0.3s var(--easing);
            @include schermiStretti {
              height: 1.8rem;
              top: -1.1rem;
            }
          }
        }
      }
    }
  }
}
