@use "../../base/mixins.scss" as *;

.our-commitments {
  .our-commitment-pallino {
    .fade-in-up {
      .our-commitment-pallino-cont.pallino-thefuture {
        .our-commitments-tooltip {
          .our-commitments-tooltip-cont {
            background-color: var(--azzurro);
            border: 2px solid var(--azzurro);
            .our-commitments-tooltip-titolo {
              height: 2.5rem !important;
            }
          }
          & > svg {
            polygon {
              fill: var(--azzurro);
            }
          }
        }
      }
    }
  }

  .our-commitment-pallino:not(#theFuture1) {
    @include hover {
      .fade-in-up
        .our-commitment-pallino-cont.pallino-thefuture
        .our-commitments-tooltip
        > svg {
        transform: translateY(-1rem) rotate(180deg);
      }
    }
    .fade-in-up {
      .our-commitment-pallino-cont.pallino-thefuture {
        .our-commitments-tooltip {
          .our-commitments-tooltip-cont {
            transform: translateY(3.2rem);
          }
          & > svg {
            top: 1.7rem;
            transform: translateY(-1rem) rotate(180deg);
          }
        }
      }
    }
  }

  #theFuture1 {
    top: 40vh;
    left: 36vh;
  }
  #theFuture2 {
    top: 19vh;
    left: 101.3vh;
    .our-commitments-tooltip {
      left: calc(-3.7rem + 1.5vh);
      & > svg {
        margin-left: 3.1rem;
      }
    }
  }
  #theFuture3 {
    top: 7vh;
    left: 112.3vh;
    .our-commitments-tooltip {
      left: calc(-3.7rem + 1.5vh);
      & > svg {
        margin-left: 3.1rem;
      }
    }
  }
  #theFuture4 {
    top: 16vh;
    left: 125.3vh;
    .our-commitments-tooltip {
      left: calc(-3.7rem + 1.5vh);
      & > svg {
        margin-left: 3.1rem;
      }
    }
  }
}
