:root {
  --font-size-158pt: 7rem;
  --font-size-96pt: 4.5rem;
  --font-size-72pt: 3.4rem;
  --font-size-60pt: 2.82rem;
  --font-size-48pt: 2.2rem;
  --font-size-37pt: 1.8rem;
  --font-size-34pt: 1.5rem;
  --font-size-28pt: 1.35rem;
  --font-size-24pt: 1.2rem;
  --font-size-22pt: 1rem;
  --font-size-20pt: 0.92rem;
  --font-size-16pt: 0.8rem;
  --font-size-15pt: 0.7rem;
  --font-size-14pt: 0.65rem;
  --font-size-13pt: max(9px, 0.6rem);

  --padding-piccolo: 5vw;
  --padding-grande: 10.5vw;

  --verde-glow: #63ea70;
  --verde: #3ea839;
  --verde-scuro: #00682f;
  --grigio: #e6e6e6;
  --grigio-medio: #b1b2b2;
  --grigio-scuro: #989898;
  --verdino: #62bb95;
  --giallo: #ffde00;
  --rosso: #f05624;
  --azzurro: #6ac8f0;
  --rosa: #e94591;
  --viola: #4c308c;
  --verde-acqua: #2e9cad;

  --easing: cubic-bezier(0, 0, 0.22, 1);
}
