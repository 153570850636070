@use "../../base/mixins.scss" as *;

.our-commitments {
  .our-commitment-pallino {
    .our-commitment-pallino-cont.pallino-discover {
      .our-commitments-tooltip {
        .our-commitments-tooltip-cont {
          background-color: var(--verdino);
          border: 2px solid var(--verdino);
        }
        & > svg {
          polygon {
            fill: var(--verdino);
          }
        }
      }
    }
  }
  #discover1 {
    top: 48vh;
    left: 18vh;
  }
  #discover2 {
    top: 43vh;
    left: 28vh;
  }
  #discover3 {
    top: 48vh;
    left: 51vh;
  }
  #discover4 {
    top: 58vh;
    left: 69vh;
  }
  #discover5 {
    top: 40.5vh;
    left: 68vh;
  }
  #discover6 {
    top: 27.5vh;
    left: 59vh;
  }
  #discover7 {
    top: 27.5vh;
    left: 79vh;
  }
  #discover8 {
    top: 27.5vh;
    left: 95vh;
  }
  #discover9 {
    top: 43vh;
    left: 100vh;
  }
  #discover10 {
    top: 30vh;
    left: 120vh;
  }
  #discover11 {
    top: 57vh;
    left: 128vh;
  }
}
