@use "../../base/mixins.scss" as *;

.our-commitments {
  #mappa-our-commitments-cont {
    & > div {
      will-change: transform;
    }
    .pop-up {
      position: absolute;
      width: auto;
      z-index: 15;
      img {
        height: 100%;
        width: auto;
      }
    }
    #casetta-sopra {
      position: absolute;
      top: 16.4vh;
      left: 66.2vh;
      height: 5.8vh;
      width: auto;
      z-index: 15;
      img {
        width: auto;
        height: 100%;
      }
    }
    #rifiutiProd {
      top: 39.1vh;
      left: 16.6vh;
      height: 4.3vh;
    }
    #fusione {
      top: 41.6vh;
      left: 18.2vh;
      height: 13.4vh;
    }
    #escavatrice1 {
      top: 45vh;
      left: 9.5vh;
      height: 2.5vh;
    }
    #rottami1 {
      top: 46.3vh;
      left: 12.3vh;
      height: 2.6vh;
    }
    #rottami2 {
      top: 48.2vh;
      left: 15.4vh;
      height: 2.6vh;
    }
    #rottami3 {
      top: 49.7vh;
      left: 18.3vh;
      height: 2.6vh;
    }
    #rottami4 {
      top: 51.6vh;
      left: 21.6vh;
      height: 2.6vh;
    }
    #escavatrice2 {
      top: 53.4vh;
      left: 24.3vh;
      height: 2.5vh;
    }
    #containerRifiuti1 {
      top: 53.6vh;
      left: 29.3vh;
      height: 3.5vh;
    }
    #containerRifiuti2 {
      top: 55vh;
      left: 27.3vh;
      height: 3.5vh;
    }
    #pilastri {
      top: 41.5vh;
      left: 6.8vh;
      height: 14.7vh;
    }
    #prodEdilizia {
      top: 44.5vh;
      left: 37.7vh;
      height: 15.4vh;
    }
    #magazzino {
      top: 38.5vh;
      left: 65.7vh;
      height: 7.6vh;
    }
    #basePannelliFonderia {
      top: 32.8vh;
      left: 21vh;
      height: 8vh;
    }
    #pannelloVerticaleSxFonderia1 {
      top: 32.7vh;
      left: 25.5vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderia2 {
      top: 34.2vh;
      left: 28vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderia3 {
      top: 35.7vh;
      left: 30.5vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderia4 {
      top: 33.6vh;
      left: 23.9vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderia5 {
      top: 35.1vh;
      left: 26.4vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderia6 {
      top: 36.6vh;
      left: 28.9vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderia7 {
      top: 34.6vh;
      left: 22.3vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderia8 {
      top: 35.95vh;
      left: 24.8vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderia9 {
      top: 37.4vh;
      left: 27.3vh;
      height: 2vh;
    }

    #basePannelliFonderiaDestra {
      top: 38.7vh;
      left: 31.1vh;
      height: 8vh;
    }
    #pannelloVerticaleSxFonderiaDestra1 {
      top: 38.7vh;
      left: 35.6vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderiaDestra2 {
      top: 40.1vh;
      left: 38.1vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderiaDestra3 {
      top: 41.6vh;
      left: 40.7vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderiaDestra4 {
      top: 39.6vh;
      left: 34vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderiaDestra5 {
      top: 41vh;
      left: 36.6vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderiaDestra6 {
      top: 42.5vh;
      left: 39.2vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderiaDestra7 {
      top: 40.4vh;
      left: 32.5vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderiaDestra8 {
      top: 41.8vh;
      left: 35vh;
      height: 2vh;
    }
    #pannelloVerticaleSxFonderiaDestra9 {
      top: 43.25vh;
      left: 37.5vh;
      height: 2vh;
    }
    #specialities {
      top: 54.6vh;
      left: 60vh;
      height: 12.3vh;
    }
    #baseRifiutiTreno {
      top: 57.3vh;
      left: 125vh;
      height: 8.4vh;
    }
    #rifiutiTrenoRottami1 {
      top: 57.8vh;
      left: 133.2vh;
      height: 3vh;
    }
    #rifiutiTrenoRottami2 {
      top: 59.7vh;
      left: 130.1vh;
      height: 3vh;
    }
    #rifiutiTrenoRottami3 {
      top: 61.7vh;
      left: 126.9vh;
      height: 3vh;
    }
    #trenoGrande {
      top: 67.1vh;
      left: 109.3vh;
      height: 8.4vh;
    }
    #trenoPiccolo {
      top: 69.8vh;
      left: 57.5vh;
      height: 7.5vh;
    }
    #fabbrica1 {
      top: 23.8vh;
      left: 56vh;
      height: 11.5vh;
    }
    #fabrica1ContainerCamion2 {
      top: 33.8vh;
      left: 59.5vh;
      height: 3.5vh;
    }
    #capannone {
      top: 25.6vh;
      left: 74.1vh;
      height: 10vh;
    }
    #capannoneContainerCamion {
      top: 33.6vh;
      left: 77.8vh;
      height: 3.5vh;
    }
    #fabbrica2gabbiotto {
      top: 28vh;
      left: 89.4vh;
      height: 3.8vh;
    }
    #fabbrica2 {
      top: 25vh;
      left: 91.2vh;
      height: 12vh;
    }
    #fabrica2CamionRimorchio {
      top: 35.2vh;
      left: 94.6vh;
      height: 3.3vh;
    }
    #ponte {
      top: 34.3vh;
      left: 135.9vh;
      height: 7.5vh;
    }
    #rullo {
      top: 33.2vh;
      left: 123.9vh;
      height: 3vh;
    }
    #gru {
      top: 25.4vh;
      left: 124.8vh;
      height: 8.3vh;
    }
    #palazzoCostruzione {
      top: 23.1vh;
      left: 129.5vh;
      height: 11.5vh;
    }
    #gabbiottoCantiere {
      top: 32.6vh;
      left: 130.1vh;
      height: 3.6vh;
    }
    #spalatrice {
      top: 32.3vh;
      left: 137.4vh;
      height: 3.2vh;
    }
    #personeCantiere {
      top: 34.2vh;
      left: 128.7vh;
      height: 4.1vh;
    }
    #pannelloFabbrica1Verde1 {
      top: 24.4vh;
      left: 58vh;
      height: 1.8vh;
    }
    #pannelloFabbrica1Verde2 {
      top: 25.6vh;
      left: 59.9vh;
      height: 1.8vh;
    }
    #pannelloFabbrica1Verde3 {
      top: 26.8vh;
      left: 62vh;
      height: 1.8vh;
    }
    #pannelloCapannoneVerde1 {
      top: 26.7vh;
      left: 76.5vh;
      height: 2vh;
    }
    #pannelloCapannoneVerde2 {
      top: 25.6vh;
      left: 78.7vh;
      height: 2vh;
    }
    #pannelloCapannoneVerde3 {
      top: 27.5vh;
      left: 78.1vh;
      height: 2vh;
    }
    #pannelloCapannoneVerde4 {
      top: 26.4vh;
      left: 80.3vh;
      height: 2vh;
    }
    #pannelloCapannoneVerde5 {
      top: 28.3vh;
      left: 79.7vh;
      height: 2vh;
    }
    #pannelloCapannoneVerde6 {
      top: 27.2vh;
      left: 81.9vh;
      height: 2vh;
    }
    #pannelloFabbrica2Verde1 {
      top: 25.6vh;
      left: 93.5vh;
      height: 1.8vh;
    }
    #pannelloFabbrica2Verde2 {
      top: 26.8vh;
      left: 95.4vh;
      height: 1.8vh;
    }
    #pannelloFabbrica3Verde3 {
      top: 28.1vh;
      left: 97.5vh;
      height: 1.8vh;
    }
    #villaggio-rosa1 {
      position: absolute;
      width: auto;
      z-index: 15;
      top: 20.7vh;
      left: 25.1vh;
      .fade-in-up {
        width: auto;
        height: 8.2vh;
        transform: translate3d(0, 0, 0);
        img {
          width: auto;
          height: 100%;
        }
      }
    }
    #villaggio-rosa2 {
      position: absolute;
      width: auto;
      z-index: 15;
      top: 25.1vh;
      left: 35.2vh;
      .fade-in-up {
        width: auto;
        height: 5.8vh;
        transform: translate3d(0, 0, 0);
        img {
          width: auto;
          height: 100%;
        }
      }
    }
    #villaggio-rosa3 {
      position: absolute;
      width: auto;
      z-index: 15;
      top: 27.5vh;
      left: 39.4vh;
      .fade-in-up {
        width: auto;
        height: 5.8vh;
        transform: translate3d(0, 0, 0);
        img {
          width: auto;
          height: 100%;
        }
      }
    }
    #villaggio-rosa4 {
      position: absolute;
      width: auto;
      z-index: 15;
      top: 30.1vh;
      left: 31.6vh;
      .fade-in-up {
        width: auto;
        height: 5.8vh;
        transform: translate3d(0, 0, 0);
        img {
          width: auto;
          height: 100%;
        }
      }
    }
    #fondoParcoEolico {
      top: 4.8vh;
      left: 90.4vh;
      height: 18.8vh;
    }
    #palaEolica1 {
      top: 8vh;
      left: 99.4vh;
      height: 11.3vh;
    }
    #palaEolica2 {
      top: 6.4vh;
      left: 102.1vh;
      height: 11.3vh;
    }
    #palaEolica3 {
      top: 9vh;
      left: 106.9vh;
      height: 11.3vh;
    }
    #palaEolica4 {
      top: 10.6vh;
      left: 104.1vh;
      height: 11.3vh;
    }
    #fondoIdrogeno {
      top: 2.3vh;
      left: 95.2vh;
      height: 8.6vh;
    }
    #idrogeno {
      top: 3.6vh;
      left: 104.7vh;
      height: 5.6vh;
    }
    #fattoria {
      top: 6.6vh;
      left: 115.3vh;
      height: 14.7vh;
    }
  }
}
